import { Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";
export class WristEFEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  minWristEF!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  avrgWristEF!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  maxWristEF!: number;
}
