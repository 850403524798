import { Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";
export class FingerSpreadEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  minFingerSpread!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  avrgFingerSpread!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  maxFingerSpread!: number;
}
