export const QueryKeys = {
  adminQuery: "admins",
  userQuery: "user",
  forgotPasswordConfirmQuery: "forgotPassword",
  games: "games",
  clientQuery: "client",
  oneClient: "one-client",
  hand: "hand",
  clientGame: "client-game",
};
