import { Form } from "antd";

type FormProps = {
  children: JSX.Element[] | JSX.Element;
  name: string;
  onFinish: () => void;
  remember: boolean;
  layout: "horizontal" | "vertical" | "inline";
  scrollToFirstError: boolean;
};

export function CustomForm({
  children,
  name,
  onFinish,
  remember,
  layout,
  scrollToFirstError,
}: FormProps) {
  return (
    <Form
      name={name}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: remember }}
      autoComplete="off"
      layout={layout}
      scrollToFirstError={scrollToFirstError}
    >
      {children}
    </Form>
  );
}
