import { message } from "antd";
import React, { useEffect } from "react";
import { ErrorHandler } from "../../constants";

type MessageProps = {
  type: "error" | "warning" | "success" | "info" | "loading";
  content: string;
  error?: any;
  onClose: () => void;
};

export function Message({ type, content, error, onClose }: MessageProps) {
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 1 });

  useEffect(() => {
    return messageApi.open({
      type,
      content: error
        ? ErrorHandler({ code: error?.response.data.code })
        : content,
      onClose,
    });
  }, [type, content, messageApi, onClose, error]);

  return <div>{contextHolder}</div>;
}
