import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LogoutOutlined } from "@ant-design/icons";
import { logoutAction } from "../../actions";
import { QueryKeys, useAppNavigate } from "../../constants";
import "./icon.scss";

export function LogoutIcon() {
  const queryClient = useQueryClient();
  const navigate = useAppNavigate();
  const { mutate } = useMutation({
    mutationFn: logoutAction,
    onSuccess: () => {
      localStorage.removeItem("token");
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeys.userQuery,
          QueryKeys.adminQuery,
          QueryKeys.clientGame,
          QueryKeys.forgotPasswordConfirmQuery,
          QueryKeys.games,
          QueryKeys.hand,
          QueryKeys.oneClient,
        ],
      });
    },
  });
  return (
    <LogoutOutlined
      className="icon"
      onClick={() => {
        mutate();
        navigate("/");
      }}
    />
  );
}
