import axios from "axios";

type GetClientDataType = {
  id: string | undefined;
};

export async function getClientDataAction({ id }: GetClientDataType) {
  const token = localStorage.getItem("token");

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/client/user?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
