import { Slide } from "react-slideshow-image";

type SliderProps = {
  children: JSX.Element[];
};

export function Slider({ children }: SliderProps) {
  return (
    <Slide canSwipe={false} autoplay={false}>
      {children}
    </Slide>
  );
}
