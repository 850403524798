import { Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";
export class FingerHyperExtgensionEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  minFingerHyperextgension!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  avrgFingerHyperextgension!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  maxFingerHyperextgension!: number;
}
