import { Modal } from "antd";
import { useEffect, useRef } from "react";
import { ErrorHandler, useAppState, useAppTranslation } from "../../constants";
import { CustomButton } from "../button";
import { ErrorIcon } from "../icon";

type ErrorModalProps = {
  error: any | null;
  handleClose: () => void;
};

export function ErrorModal({ error, handleClose }: ErrorModalProps) {
  const timerRef = useRef<NodeJS.Timeout>();
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    timer: 0,
  });

  useEffect(() => {
    if (typeof error?.response?.data?.data === "number") {
      clearTimeout(timerRef.current);
      timerRef.current = setInterval(() => {
        const remaining = Math.floor(
          (new Date(error?.response.data.data).getTime() -
            new Date().getTime()) /
            1000
        );
        setState((prevState) => ({ ...prevState, timer: remaining }));
      }, 100);
    }
    if (state.timer! === 0) {
      setState((prevState) => ({ ...prevState, timer: null! }));
      clearInterval(timerRef.current);
    } else if (state.timer <= 1 && state.timer !== null) {
      return handleClose;
    } else {
      return () => {
        clearInterval(timerRef.current);
      };
    }
  }, [error?.response?.data?.data, state, setState, handleClose]);

  return (
    <Modal
      maskClosable={false}
      centered={true}
      title={
        <div>
          <ErrorIcon /> {ErrorHandler({ code: error?.response?.data?.code })}
        </div>
      }
      open={true}
      onCancel={handleClose}
      footer={[
        <CustomButton
          type="default"
          key="back"
          onClick={handleClose}
          htmlType="button"
        >
          <div className="button-text">{t("cancel")}</div>
        </CustomButton>,
        <CustomButton
          type="primary"
          key="submit"
          onClick={handleClose}
          htmlType="button"
        >
          <div className="button-text">{t("ok")}</div>
        </CustomButton>,
      ]}
    >
      {error?.response?.data?.data ? state.timer : ""}
    </Modal>
  );
}
