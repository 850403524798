import { useQuery } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import { QueryKeys } from "../query-keys";
import { getMeAction } from "../../actions";
import { ErrorModal } from "../../components";
import { useAppState } from "../hooks";

interface RequireAuthProps {
  children: JSX.Element;
}

export const AuthHandler = ({ children }: RequireAuthProps) => {
  const [state, setState] = useAppState({
    modalVisible: false,
  });

  const { data, error, isLoading } = useQuery({
    queryKey: [QueryKeys.userQuery],
    queryFn: getMeAction,
    staleTime: 1000 * 60 * 5,
    retry: 0,
  });

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  if (!isLoading && (data === null || data === undefined || error)) {
    if (window.location.pathname.includes("/cms")) {
      return <Navigate to={"/"} />;
    }
  }

  if (!isLoading && data.user?.firstLogin) {
    return <Navigate to={"/first-login"} />;
  }

  return (
    <main>
      {children}
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </main>
  );
};
