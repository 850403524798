import { Input } from "antd";

type CustomInputProps = {
  bordered: boolean;
  id: string;
  maxLength: number;
  showCount?: boolean;
  status: "error" | "warning" | undefined;
  type: "text" | "textarea";
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  onResize?: any;
  defaultValue?: string;
  suffix?: JSX.Element;
  prefix?: JSX.Element;
  disabled?: boolean;
};

export function CustomInput({
  bordered,
  id,
  maxLength,
  showCount,
  status,
  prefix,
  type,
  value,
  onChange,
  placeholder,
  onResize,
  defaultValue,
  suffix,
  disabled,
}: CustomInputProps) {
  return (
    <Input
      bordered={bordered}
      id={id}
      maxLength={maxLength}
      showCount={showCount}
      status={status}
      prefix={prefix}
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      onResize={onResize}
      defaultValue={defaultValue}
      suffix={suffix}
      disabled={disabled}
    />
  );
}
