import { useAppTranslation } from "../../constants";
import { ClientsTable, Slideshow } from "./components";
import "./global-statistics.page.scss";

export function GlobalStatisticsPage() {
  const { t } = useAppTranslation();

  return (
    <main className="global-statistics-page">
      <h1>{t("statistic-page-title")}</h1>
      <Slideshow />
      <ClientsTable />
    </main>
  );
}
