import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../../constants";
import { loginAction } from "../../../actions";
import {
  CustomButton,
  CustomForm,
  CustomInput,
  ErrorModal,
  FormItem,
  PasswordInput,
  Spacer,
  UserIcon,
} from "../../../components";
import { Link } from "react-router-dom";

export function LoginForm() {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    email: "",
    password: "",
    modalVisible: false,
  });
  const { mutate, error, isPending } = useMutation({
    mutationFn: loginAction,
    onSuccess: (data) => {
      localStorage.setItem("token", data.token.accessToken);
      queryClient.setQueryData([QueryKeys.userQuery], data);
      navigate("/cms");
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
    retry: 0,
  });

  const handleEmailChange = (e: string) => {
    setState((prevState) => ({ ...prevState, email: e }));
  };

  const handlePasswordChange = (e: string) => {
    setState((prevState) => ({ ...prevState, password: e }));
  };

  const handleLogin = () => {
    mutate({
      emailAddress: state.email,
      password: state.password,
    });
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };
  return (
    <div>
      <CustomForm
        name="login"
        onFinish={handleLogin}
        remember={true}
        layout="vertical"
        scrollToFirstError={true}
      >
        <FormItem
          hasFeedback={false}
          label={t("email")}
          name="email"
          required={false}
          rules={[]}
        >
          <CustomInput
            bordered={true}
            id="email"
            maxLength={255}
            status={undefined}
            type="text"
            value={state.email}
            onChange={handleEmailChange}
            placeholder={t("email")}
            suffix={<UserIcon />}
          />
        </FormItem>
        <FormItem
          hasFeedback={false}
          label={t("password")}
          name="password"
          required={false}
          rules={[]}
        >
          <PasswordInput
            id="password"
            bordered={true}
            value={state.password}
            onChange={handlePasswordChange}
            status={undefined}
            placeholder={t("password")}
          />
        </FormItem>
        <div className="submit-button-container">
          <div className="submit-button">
            <Spacer size="middle" direction="vertical">
              <Link to={"/forgot-password"} style={{ color: "black" }}>
                {t("forgot-password")}
              </Link>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  type="primary"
                  key="submit-button"
                  htmlType="submit"
                  loading={isPending}
                >
                  <span className="button-text">{t("login")}</span>
                </CustomButton>
              </div>
            </Spacer>
          </div>
        </div>
      </CustomForm>
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </div>
  );
}
