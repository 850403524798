import { Expose } from "class-transformer";
import { IsDefined, IsNumber, IsString } from "class-validator";
export class AdminEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  emailAddress!: string;

  @Expose()
  @IsDefined()
  @IsString()
  name!: string;
}
