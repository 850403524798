import {CustomChart} from "../../../../components";
import {useAppTranslation} from "../../../../constants";

interface WristExtensionChartProps {
    stats: {
        feelingStats: any,
        handStats: any
    }
}

export const WristExtensionChart = (props: WristExtensionChartProps) => {
    const { t } = useAppTranslation();

    const max = Math.abs(props.stats.handStats.healthyHand.wristSideRight) || 90;
    const min = 0;

    const data = props.stats.feelingStats.data.map((d: any) => {
        const measures = d.measures.filter((m: any) => m.exerciseIndex === 7);
        if (measures.length === 0) {
            return null
        }
        let value = (Math.abs(measures[measures.length - 1].measuredData.wristSideRight) - min) / (max - min);
        if (value > 1) {
            value = 1;
        }
        if (value < 0) {
            value = 0;
        }
        return {
            day: d.day,
            value: Math.round(value * 90 * 100) / 100
        }
    }).filter((d: any) => d !== null)
    if (data.find((d: any) => d.day === 1) === undefined) {
        data.push({ day: 1, value: null })
    }
    if (data.find((d: any) => d.day === 60) === undefined) {
        data.push({ day: 60, value: null })
    }
    data.sort((a: any, b: any) => a.day - b.day);

    return (
        <CustomChart
            type="line"
            chartId="pain-after"
            dateTimes={data.map((data: any) => `Day ${ data.day }`)}
            minSeriesName=""
            minData={data.map((data: any) => data.value)}
            width={"548"}
            height={"400"}
            chartTitle={t("wrist-extension-chart-title")}
            stepperTitle={""}
            poz="top"
            max={90}
            min={0}
            markerSize={4}
        />
    )
}