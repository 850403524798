import {CustomChart} from "../../../../components";
import {useAppTranslation} from "../../../../constants";

interface PainAfterChartProps {
    stats: {
        feelingStats: any,
        handStats: any
    }
}

export const PainAfterChart = (props: PainAfterChartProps) => {
    const { t } = useAppTranslation();

    const data = props.stats.feelingStats.data.map((d: any) => ({ day: d.day, value: Math.round(d.feelings.painAfter / 1.1 * 100) / 100 }))
    if (data.find((d: any) => d.day === 1) === undefined) {
        data.push({ day: 1, value: null })
    }
    if (data.find((d: any) => d.day === 60) === undefined) {
        data.push({ day: 60, value: null })
    }
    data.sort((a: any, b: any) => a.day - b.day);

    return (
        <CustomChart
            type="line"
            chartId="pain-after"
            dateTimes={data.map((data: any) => `Day ${ data.day }`)}
            minSeriesName=""
            minData={data.map((data: any) => data.value)}
            width={"548"}
            height={"400"}
            chartTitle={t("pain-after-chart-title")}
            stepperTitle={""}
            poz="top"
            max={10}
            min={0}
            markerSize={4}
        />
    )
}