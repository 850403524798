import { ThumbIcon } from "../../components";
import { useAppTranslation } from "../../constants";
import "./success.page.scss";

export function SuccessPage() {
  const { t } = useAppTranslation();
  return (
    <div className="success-container">
      <div className="success-title">
        <ThumbIcon />
      </div>
      <div className="success-subtitle">{t("password-has-changed")}</div>
    </div>
  );
}
