import { Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";
export class FingertipEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  minFingertip!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  avrgFingertip!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  maxFingertip!: number;
}
