import { Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";
export class FingerExtensionEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  minFingerExtension!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  avrgFingerExtension!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  maxFingerExtension!: number;
}
