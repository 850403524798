import { useMutation, useQuery } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../../constants";
import {
  forgotPasswordConfirmAction,
  modifyPasswordAction,
} from "../../../actions";
import {
  CustomButton,
  CustomForm,
  FormItem,
  LoadingIcon,
  Message,
  PasswordInput,
} from "../../../components";

export function ForgotPasswordConfirmForm() {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();

  const getQueryParam = new URLSearchParams(window.location.search);
  const token = getQueryParam.get("token");
  const [state, setState] = useAppState({
    password: "",
    passwordCheck: "",
    infoModalVisible: false,
  });
  const { data, error, isLoading } = useQuery({
    queryKey: [QueryKeys.forgotPasswordConfirmQuery],
    queryFn: () => forgotPasswordConfirmAction(token as string),
    retry: 0,
  });

  const handlePasswordChange = (value: string) => {
    setState((prevState) => ({ ...prevState, password: value }));
  };

  const handlePasswordCheckChange = (value: string) => {
    setState((prevState) => ({ ...prevState, passwordCheck: value }));
  };

  const {
    mutate,
    error: mutateError,
    isPending,
  } = useMutation({
    mutationFn: (data: any) => modifyPasswordAction(data),
    onSuccess: () => {
      navigate("/success");
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleSubmit = () => {
    mutate({
      password: state.password,
      id: data.user.id,
      token,
    });
  };

  const handleModalClose = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  return (
    <div>
      {isLoading ? (
        <LoadingIcon />
      ) : error ? (
        <h1>{t("forgot-password-error")}</h1>
      ) : (
        <CustomForm
          name="forgot-password-confirm"
          onFinish={handleSubmit}
          remember={false}
          layout="vertical"
          scrollToFirstError={false}
        >
          <FormItem
            hasFeedback={false}
            tooltip={t("min-password-requirement")}
            label={t("password")}
            name="password"
            required={true}
            rules={[
              {
                required: true,
                min: 8,
                max: 255,
                whitespace: true,
                message: t(""),
              },
            ]}
          >
            <PasswordInput
              id="password"
              bordered={true}
              value={state.password}
              onChange={handlePasswordChange}
              status={undefined}
              placeholder={t("password")}
            />
          </FormItem>
          <FormItem
            hasFeedback={false}
            tooltip={t("min-password-requirement")}
            label={t("password-confirm")}
            name="password-confirm"
            required={true}
            rules={[
              {
                required: state.password?.length > 0,
                min: 8,
                max: 255,
                whitespace: true,
                message: t("min-password-requirement"),
              },
            ]}
          >
            <PasswordInput
              id="password-confirm"
              bordered={true}
              value={state.passwordCheck}
              onChange={handlePasswordCheckChange}
              status={undefined}
              placeholder={t("password-confirm")}
            />
          </FormItem>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomButton
              type="primary"
              key="submit-button"
              htmlType="submit"
              loading={isPending}
              disabled={
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(state.password) ||
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(
                  state.passwordCheck
                ) ||
                state.password !== state.passwordCheck
              }
            >
              <span className="button-text">{t("confirm")}</span>
            </CustomButton>
          </div>
        </CustomForm>
      )}
      {state.infoModalVisible && (
        <Message
          type="error"
          content={t("forgot-password-error")}
          error={mutateError}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
}
