import { Outlet } from "react-router-dom";
import { CustomHeader } from "../../components";
import "./default.layout.scss";

export function DefaultLayout() {
  return (
    <main className="default-layout">
      <CustomHeader />
      <div className="background-overlay"></div>
      <Outlet />
    </main>
  );
}
