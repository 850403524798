import { Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";
export class BigFistEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  minBigFist!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  avrgBigFist!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  maxBigFist!: number;
}
