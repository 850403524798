import { ClientEntity, useAppTranslation } from "../../../constants";
import { DataTable } from "../../../components";

type ClientTaleProps = {
  client: ClientEntity | undefined;
};

export function ClientTable({ client }: ClientTaleProps) {
  const { t } = useAppTranslation();

  const columns = [
    {
      title: t("name"),
      key: t("name"),
      dataIndex: "name",
    },
    {
      title: t("email"),
      key: t("email"),
      dataIndex: "email",
    },
    {
      title: t("gender"),
      key: t("gender"),
      dataIndex: "gender",
      render: (text: string, record: ClientEntity) => {
        return t(record.gender);
      },
    },
    {
      title: t("birth-date"),
      key: t("birth-date"),
      dataIndex: "birthDate",
      render: (text: string, record: ClientEntity) => {
        return record.birthDate ?? "";
      },
    },
    {
      title: t("operation-type"),
      key: t("operation-type"),
      dataIndex: "operationType",
      render: (text: string, record: ClientEntity) => {
        return t(record.operationType);
      },
    },
    {
      title: t("surgery-date"),
      key: t("surgery-date"),
      dataIndex: "surgeryDate",
    },
    {
      title: t("cast-wearing-duration"),
      key: t("cast-wearing-duration"),
      dataIndex: "castWearingDuration",
      render: (text: string, record: ClientEntity) => {
        return t(record.castWearingDuration);
      },
    },
    {
      title: t("when-to-remove-stitches-date"),
      key: t("when-to-remove-stitches-date"),
      dataIndex: "whenToRemoveStitchesDate",
    },
    {
      title: t("weigth-the-arm-date"),
      key: t("weigth-the-arm-date"),
      dataIndex: "weigthTheArmDate",
      render: (text: string, record: ClientEntity) => {
        return t(record.weigthTheArmDate);
      },
    },
    {
      title: t("remove-implants"),
      key: t("remove-implants"),
      dataIndex: "removeImplants",
      render: (text: string, record: ClientEntity) => {
        return t(record.removeImplants);
      },
    },
    {
      title: t("handedness"),
      key: t("handedness"),
      dataIndex: "handedness",
      render: (text: string, record: ClientEntity) => {
        return t(record.handedness);
      },
    },
    {
      title: t("injured"),
      key: t("injured"),
      dataIndex: "injured",
      render: (text: string, record: ClientEntity) => {
        return t(`${record.injured}`);
      },
    },
    {
      title: t("injury-date"),
      key: t("injury-date"),
      dataIndex: "injuryDate",
    },
    {
      title: t("injured-site"),
      key: t("injured-site"),
      dataIndex: "injuredSite",
      render: (text: string, record: ClientEntity) => {
        return t(`${record.injuredSite}`);
      },
    },
    {
      title: t("surgery"),
      key: t("surgery"),
      dataIndex: "surgery",
      render: (text: string, record: ClientEntity) => {
        return t(`${record.surgery}`);
      },
    },
    {
      title: t("immobilized"),
      key: t("immobilized"),
      dataIndex: "immobilized",
      render: (text: string, record: ClientEntity) => {
        return t(`${record.immobilized}`);
      },
    },
    {
      title: t("stitched"),
      key: t("stitched"),
      dataIndex: "stitched",
      render: (text: string, record: ClientEntity) => {
        return t(`${record.stitched}`);
      },
    },
    {
      title: t("diagnosis"),
      key: t("diagnosis"),
      dataIndex: "diagnosis",
      render: (text: string, record: ClientEntity) => {
        return t(record.diagnosis);
      },
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        dataSource={[client]}
        functionDisabler={true}
        onRowClick={() => null}
        pagination={false}
        scroll={true}
      />
    </div>
  );
}
