import axios from "axios";

type LoginProps = {
  emailAddress: string;
  password: string;
};

export async function loginAction({ emailAddress, password }: LoginProps) {
  const params = {
    emailAddress,
    password,
  };

  return await axios
    .post(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/user/login`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
