import { Form } from "antd";

type FormItemProps = {
  hasFeedback: boolean;
  label: string;
  name: string;
  required: boolean;
  rules?: any[];
  tooltip?: any;
  children: JSX.Element;
};

export function FormItem({
  hasFeedback,
  label,
  name,
  required,
  rules,
  tooltip,
  children,
}: FormItemProps) {
  return (
    <Form.Item
      hasFeedback={hasFeedback}
      label={label}
      name={name}
      required={required}
      rules={rules}
      tooltip={tooltip}
    
    >
      {children}
    </Form.Item>
  );
}
