import { Button } from "antd";

type ButtonProps = {
  onClick?: () => void;
  children: JSX.Element;
  type: "primary" | "link" | "text" | "default" | "dashed" | undefined;
  key: string;
  htmlType: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  loading?: boolean;
};

export function CustomButton({
  onClick,
  children,
  type,
  key,
  htmlType,
  disabled,
  loading,
}: ButtonProps) {
  return (
    <Button
      onClick={onClick}
      type={type}
      key={key}
      disabled={disabled}
      loading={loading}
      htmlType={htmlType}
      className="icon"
    >
      {children}
    </Button>
  );
}
