import { useQuery } from "@tanstack/react-query";
import { getGlobalGameDataAction } from "../../../actions";
import { LoadingIcon, SlideShow } from "../../../components";
import { QueryKeys } from "../../../constants";

export function Slideshow() {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.games],
    queryFn: getGlobalGameDataAction,
  });

  return (
    <div>{isLoading ? <LoadingIcon /> : <SlideShow games={data.games} />}</div>
  );
}
