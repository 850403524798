import { Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";
export class MCPFlexionEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  minMcpFlexion!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  avrgMcpFlexion!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  maxMcpFlexion!: number;
}
