import { useTranslation } from 'react-i18next'
import './data-deletion.page.scss'

export const DataDeletionPage = () => {
    const { t } = useTranslation()

    return (
        <main className="page--data-deletion">
            <div className="data-deletion-div">
                <h2>{t('data_deletion_request_title')}</h2>

                <label className="title">{t('data_deletion_request_company_title')}</label>
                <label className="sub-title">{t('data_deletion_request_company_name_title')}</label>
                <label className="title">{t('data_deletion_request_mobile_app_title')}</label>
                <label className="sub-title">{t('data_deletion_request_mobile_app_name_title')}</label>

                <label className="description">{t('data_deletion_request_description')}</label>

            </div>
        </main>
    )
}
