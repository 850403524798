import { FirstLoginForm } from "./components";
import "./first-login.page.scss";

export function FirstLoginPage() {
  return (
    <main className="first-login-page">
      <FirstLoginForm />
    </main>
  );
}
