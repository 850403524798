import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CustomButton,
  CustomForm,
  CustomInput,
  FormItem,
  Noti,
  PasswordInput,
  UserIcon,
} from "../../../components";
import { QueryKeys, useAppState, useAppTranslation } from "../../../constants";
import { getMeAction, modifyProfileAction } from "../../../actions";
import "./profile-form.scss";

export function ProfileForm() {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: [QueryKeys.userQuery],
    queryFn: getMeAction,
    refetchOnWindowFocus: false,
  });

  const [state, setState] = useAppState({
    email: data?.user.emailAddress,
    name: data?.user.name,
    password: data?.user.password,
    passwordCheck: "",
    notiVisible: false,
    successVisible: false,
  });

  const { mutate, error } = useMutation({
    mutationFn: () =>
      modifyProfileAction({ name: state.name, password: state.password }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.userQuery] });
      setState((prevState) => ({ ...prevState, successVisible: true }));
    },
    onError() {
      setState((prevState) => ({ ...prevState, notiVisible: true }));
    },
  });

  const handleUserNameChange = (value: string) => {
    setState((prevState) => ({ ...prevState, name: value }));
  };

  const handlePasswordChange = (value: string) => {
    setState((prevState) => ({ ...prevState, password: value }));
  };

  const handlePasswordCheckChange = (value: string) => {
    setState((prevState) => ({ ...prevState, passwordCheck: value }));
  };

  const handleNotiClose = () => {
    setState((prevState) => ({
      ...prevState,
      successVisible: false,
      notiVisible: false,
    }));
  };

  return (
    <div>
      <CustomForm
        name="profile-form"
        onFinish={mutate}
        remember={false}
        layout="vertical"
        scrollToFirstError
      >
        <FormItem
          label={t("email")}
          name="email"
          required
          rules={[]}
          tooltip={""}
          hasFeedback={false}
        >
          <CustomInput
            bordered
            id="email"
            maxLength={255}
            status={undefined}
            suffix={<UserIcon />}
            type="text"
            value={state.email}
            onChange={() => null}
            placeholder={state.email}
            disabled
          />
        </FormItem>

        <FormItem
          label={t("name")}
          name="name"
          required
          rules={[]}
          tooltip={""}
          hasFeedback={false}
        >
          <CustomInput
            bordered
            id="name"
            maxLength={255}
            showCount
            status={undefined}
            type="text"
            value={state.name}
            onChange={handleUserNameChange}
            placeholder={t("name")}
          />
        </FormItem>

        <FormItem
          hasFeedback={false}
          label={t("password")}
          name="Password"
          required={true}
          rules={[
            {
              required: true,
              min: 8,
              max: 255,
              whitespace: true,
              message: t("min-password-requirement"),
            },
          ]}
          tooltip={t("min-password-requirement")}
        >
          <PasswordInput
            id="password"
            bordered={true}
            value={state.password}
            onChange={handlePasswordChange}
            status={undefined}
            placeholder={t("password")}
          />
        </FormItem>
        <FormItem
          hasFeedback={false}
          label={t("password-confirm")}
          name="Password confirm"
          required={true}
          rules={[
            {
              required: state.password?.length > 0,
              min: 8,
              max: 255,
              whitespace: true,
              message: t("min-password-requirement"),
            },
          ]}
          tooltip={t("min-password-requirement")}
        >
          <PasswordInput
            id="password-confirm"
            bordered={true}
            value={state.passwordCheck}
            onChange={handlePasswordCheckChange}
            status={undefined}
            placeholder={t("password-confirm")}
          />
        </FormItem>
        <div className="submit-button-container">
          <div className="submit-button">
            <CustomButton
              type="primary"
              key="submit-button"
              htmlType="submit"
              disabled={
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(state.password) ||
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(
                  state.passwordCheck
                ) ||
                state.password !== state.passwordCheck ||
                state.name?.length === 0
              }
            >
              <div className="button-text">{t("submit")}</div>
            </CustomButton>
          </div>
        </div>
      </CustomForm>
      {(state.notiVisible || state.successVisible) && (
        <Noti
          type={state.notiVisible ? "error" : "success"}
          message={
            state.notiVisible
              ? t("admin-modify-error")
              : t("admin-modify-success")
          }
          error={state.notiVisible ? error : null}
          handleClose={handleNotiClose}
        />
      )}
    </div>
  );
}
