import { Expose, Type } from "class-transformer";
import { IsDefined, IsString, ValidateNested } from "class-validator";
import { BigFistEntity } from "./big-fist.entity";
import { FingerExtensionEntity } from "./finger-extension.entity";
import { FingerHyperExtgensionEntity } from "./finger-hyperextgension.entity";
import { FingerSpreadEntity } from "./finger-spread.entity";
import { FingertipEntity } from "./fingertip.entity";
import { MCPFlexionEntity } from "./mcp-flexion.entity";
import { SmallFistEntity } from "./small-fist.entity";
import { TendonGlideEntity } from "./tendon-glide.entity";
import { WristEFEntity } from "./wrist-ef.entity";
import { ForearmSPEntity } from "./forearm-sp.entity";

export class GameEntity {
  @Expose()
  @IsDefined()
  @IsString()
  date!: string;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => BigFistEntity)
  bigFist!: BigFistEntity;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => FingerExtensionEntity)
  fingerExtension!: FingerExtensionEntity;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => FingerHyperExtgensionEntity)
  fingerHyperextgension!: FingerHyperExtgensionEntity;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => FingerSpreadEntity)
  fingerSpread!: FingerSpreadEntity;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => FingertipEntity)
  fingerTip!: FingertipEntity;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => ForearmSPEntity)
  forearmSP!: ForearmSPEntity;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => MCPFlexionEntity)
  mcpFlexion!: MCPFlexionEntity;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => SmallFistEntity)
  smallFist!: SmallFistEntity;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => TendonGlideEntity)
  tendonGlide!: TendonGlideEntity;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => WristEFEntity)
  wristEF!: WristEFEntity;
}
