import { LoginForm } from "./components";
import "./login.page.scss";

export function LoginPage() {
  return (
    <main className="login-page">
      <LoginForm />
    </main>
  );
}
