import { useQuery } from "@tanstack/react-query";
import { HandTitles, QueryKeys, useAppTranslation } from "../../../constants";
import { getClienthandDataAction } from "../../../actions";
import { CustomChart, Spacer } from "../../../components";
import {PainAfterChart} from "./hand-charts/pain-after-chart";
import {ForearmSupinationChart} from "./hand-charts/forearm-supination-chart";
import {ForearmPronationChart} from "./hand-charts/forearm-pronation-chart";
import {WristExtensionChart} from "./hand-charts/wrist-extension-chart";
import {WristFlexionChart} from "./hand-charts/wrist-flexion-chart";
import {ClosingFistChart} from "./hand-charts/closing-fist-chart";
import {FingerExtensionChart} from "./hand-charts/finger-extension-chart";

type HandChartsProps = {
  id: string | undefined;
  stats: any;
};

export function HandCharts({ id, stats }: HandChartsProps) {
  const { t } = useAppTranslation();

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.hand, id],
    queryFn: () => getClienthandDataAction({ id }),
  });

  return (
    <div>
      {!isLoading && (
        <Spacer size="large" direction="vertical">
          <Spacer size="large" direction="horizontal">
            <CustomChart
              type="radar"
              chartId="healthy-hand"
              dateTimes={HandTitles.map((title) => t(title))}
              minSeriesName=""
              minData={[
                data.healthyHand?.closingThumb,
                data.healthyHand?.closingIndex,
                data.healthyHand?.closingMiddle,
                data.healthyHand?.closingRing,
                data.healthyHand?.closingPinky,
                data.healthyHand?.openingThumb,
                data.healthyHand?.openingIndex,
                data.healthyHand?.openingMiddle,
                data.healthyHand?.openingRing,
                data.healthyHand?.openingPinky,
                data.healthyHand?.wristSideLeft,
                data.healthyHand?.wristSideRight,
                data.healthyHand?.rightMax,
                data.healthyHand?.leftMax,
              ]}
              width={"548"}
              height={"400"}
              chartTitle={t("healthy-hand-chart-title")}
              stepperTitle={""}
              poz="top"
            />
            <PainAfterChart stats={ stats } />
          </Spacer>
          <Spacer size="large" direction="horizontal">
            <ForearmSupinationChart stats={ stats } />
            <ForearmPronationChart stats={ stats } />
          </Spacer>
          <Spacer size="large" direction="horizontal">
            <WristExtensionChart stats={ stats } />
            <WristFlexionChart stats={ stats } />
          </Spacer>
          <Spacer size="large" direction="horizontal">
            <ClosingFistChart stats={ stats } />
            <FingerExtensionChart stats={ stats } />
          </Spacer>
        </Spacer>
      )}
    </div>
  );
}
