import { Space } from "antd";

type SpacerProps = {
  children: JSX.Element[];
  size: "large" | "middle" | "small";
  direction: "vertical" | "horizontal";
};

export function Spacer({ children, size, direction }: SpacerProps) {
  return (
    <Space size={size} direction={direction}>
      {children}
    </Space>
  );
}
