import { useAppTranslation } from "../../constants";
import { ProfileForm } from "./components";
import "./profile.page.scss";

export function ProfilePage() {
  const { t } = useAppTranslation();

  return (
    <main className="profile-page">
      <h1>{t("profile-page-title")}</h1>
      <ProfileForm />
    </main>
  );
}
