import ReactApexChart from "react-apexcharts";

type ChartProps = {
  type:
    | "line"
    | "area"
    | "bar"
    | "pie"
    | "donut"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "candlestick"
    | "boxPlot"
    | "radar"
    | "polarArea"
    | "rangeBar"
    | "rangeArea"
    | "treemap"
    | undefined;
  width: string;
  chartId: string;
  dateTimes: string[];
  minSeriesName: string;
  avrgSeriesName?: string;
  maxSeriesName?: string;
  minData: number[];
  avrgData?: number[];
  maxData?: number[];
  height: string;
  chartTitle: string;
  stepperTitle: string;
  poz: "top" | "right" | "bottom" | "left" | undefined;
  max?: number;
  min?: number;
  markerSize?: number;
};

export function CustomChart({
  chartId,
  dateTimes,
  minSeriesName,
  avrgSeriesName,
  maxSeriesName,
  minData,
  avrgData,
  maxData,
  type,
  width,
  height,
  chartTitle,
  stepperTitle,
  poz,
  max,
  min,
  markerSize
}: ChartProps) {
  const options = {
    chart: {
      id: `${chartId}`,
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: markerSize || 0,
    },
    yaxis: {
      min: min,
      max: max
    },
    xaxis: {
      categories: dateTimes,
      title: {
        text: stepperTitle,
        style: {
          cssClass: "label-text",
        },
      }
    },
    title: {
      text: chartTitle,
      style: {
        fontFamily: "var(--font-primary)", 
        fontSize: "18px", 
        cssClass: "title-text-h3",
        fontWeight: 700
      },
    },
    stroke: {
      width: 3,
    },
    legend: {
      show: true,
      position: poz,
      floating: true,
      offsetY: -25,
      offsetX: -5,
      style: {
        cssClass: "label-text",
      },
    },
  };

  const minDataFormatted = minData
    ? minData.map((value) => parseFloat(value?.toFixed(2)))
    : [];
  const avrgDataFormatted = avrgData
    ? avrgData.map((value) => parseFloat(value?.toFixed(2)))
    : [];
  const maxDataFormatted = maxData
    ? maxData.map((value) => parseFloat(value?.toFixed(2)))
    : [];

  const series =
    avrgSeriesName && maxSeriesName && avrgData && maxData && type !== "radar"
      ? [
          {
            name: minSeriesName,
            data: minDataFormatted,
          },
          {
            name: avrgSeriesName,
            data: avrgDataFormatted,
          },
          {
            name: maxSeriesName,
            data: maxDataFormatted,
          },
        ]
      : [
          {
            name: minSeriesName,
            data: minData,
          },
        ];

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type={type}
        width={width}
        height={height}
      />
    </div>
  );
}
