import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CustomButton,
  CustomForm,
  CustomInput,
  FormItem,
  Noti,
} from "../../../components";
import { createAdminAction } from "../../../actions";
import { QueryKeys, useAppState, useAppTranslation } from "../../../constants";

type AdminFormProps = {
  onCancel: () => void;
};

export function AdminForm({ onCancel }: AdminFormProps) {
  const queryClient = useQueryClient();
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    email: "",
    invalidEmail: true,
    notiVisible: false,
    successVisible: false,
  });

  const { mutate, error } = useMutation({
    mutationFn: () => createAdminAction({ email: state.email }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.adminQuery] });
      setState((prevState) => ({ ...prevState, successVisible: true }));
      handleClose();
    },
    onError() {
      setState((prevState) => ({ ...prevState, notiVisible: true }));
    },
  });

  const handleEmailChange = (value: string) => {
    setState((prevState) => ({ ...prevState, email: value }));
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, email: "" }));
    onCancel();
  };

  const validateEmail = (rule: any, value: string, callback: Function) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (value && !emailRegex.test(value)) {
      callback(t("invalid-email-message"));
      setState((prevState) => ({ ...prevState, invalidEmail: true }));
    } else {
      setState((prevState) => ({ ...prevState, invalidEmail: false }));
      callback();
    }
  };

  const handleNotiClose = () => {
    setState((prevState) => ({
      ...prevState,
      successVisible: false,
      notiVisible: false,
    }));
  };

  return (
    <div>
      <CustomForm
        name="create-admin"
        onFinish={mutate}
        remember={false}
        layout="vertical"
        scrollToFirstError={true}
      >
        <FormItem
          hasFeedback={true}
          label=""
          name=""
          required
          rules={[
            {
              required: true,
              validator: validateEmail,
              whitespace: true,
            },
          ]}
        >
          <CustomInput
            bordered
            id="email"
            maxLength={255}
            type="text"
            value={state.email}
            onChange={handleEmailChange}
            placeholder={t("email")}
            status={undefined}
          />
        </FormItem>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}
        >
          <CustomButton
            type="primary"
            htmlType="submit"
            key="create-admin-submit"
            disabled={state.email.length === 0 || state.invalidEmail}
          >
            <div className="button-text">{t("create")}</div>
          </CustomButton>
        </div>
      </CustomForm>
      {(state.notiVisible || state.successVisible) && (
        <Noti
          type={state.notiVisible ? "error" : "success"}
          message={
            state.notiVisible
              ? t("admin-creation-error")
              : t("admin-creation-success")
          }
          error={state.notiVisible ? error : null}
          handleClose={handleNotiClose}
        />
      )}
    </div>
  );
}
