import { Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";
export class TendonGlideEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  minTendonGlide!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  avrgTendonGlide!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  maxTendonGlide!: number;
}
