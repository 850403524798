import axios from "axios";

type ModifyProfileActionProps = {
  name: string;
  password: string;
};

export async function modifyProfileAction({
  name,
  password,
}: ModifyProfileActionProps) {
  const token = localStorage.getItem("token");

  const dataToSend = {
    name,
    password,
  };

  return await axios
    .patch(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/user/modify`,
      dataToSend,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}
