import { useQuery } from "@tanstack/react-query";
import { QueryKeys, useAppParams, useAppTranslation } from "../../constants";
import { getClientDataAction } from "../../actions";
import { ClientTable, HandCharts } from "./components";
import "./client.page.scss";
import {useEffect, useState} from "react";
import {Button} from "antd";
import {stringify} from "csv-stringify/browser/esm/sync";
import {Spacer} from "../../components";

export function ClientPage() {
  const param = useAppParams();
  const { t } = useAppTranslation();
  const [ stats, setStats ] = useState(null as any);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.oneClient, param?.id],
    queryFn: () => getClientDataAction({ id: param.id }),
  });

  const getHandCSV = () => {
    return [
        [ ``, `Healthy hand`, `Sick hand` ],
        [ `Opening thumb`, stats.handStats.healthyHand.openingThumb, stats.handStats.sickHand.openingThumb ],
        [ `Opening index finger`, stats.handStats.healthyHand.openingIndex, stats.handStats.sickHand.openingIndex ],
        [ `Opening middle finger`, stats.handStats.healthyHand.openingMiddle, stats.handStats.sickHand.openingMiddle ],
        [ `Opening ring finger`, stats.handStats.healthyHand.openingRing, stats.handStats.sickHand.openingRing ],
        [ `Opening pinky`, stats.handStats.healthyHand.openingPinky, stats.handStats.sickHand.openingPinky ],
        [ `Closing thumb`, stats.handStats.healthyHand.closingThumb, stats.handStats.sickHand.closingThumb ],
        [ `Closing index finger`, stats.handStats.healthyHand.closingIndex, stats.handStats.sickHand.closingIndex ],
        [ `Closing middle finger`, stats.handStats.healthyHand.closingMiddle, stats.handStats.sickHand.closingMiddle ],
        [ `Closing ring finger`, stats.handStats.healthyHand.closingRing, stats.handStats.sickHand.closingRing ],
        [ `Closing pinky`, stats.handStats.healthyHand.closingPinky, stats.handStats.sickHand.closingPinky ],
        [ `Left max`, stats.handStats.healthyHand.leftMax, stats.handStats.sickHand.leftMax ],
        [ `Right max`, stats.handStats.healthyHand.rightMax, stats.handStats.sickHand.rightMax ],
        [ `Wrist side left`, stats.handStats.healthyHand.wristSideLeft, stats.handStats.sickHand.wristSideLeft ],
        [ `Wrist side right`, stats.handStats.healthyHand.wristSideRight, stats.handStats.sickHand.wristSideRight ],
    ]
  }

    const getFeelingCSV = () => {
      const csv = [];
      for (const data of stats.feelingStats.data) {
        const csvdata = [
          [],
          [ `Day ${ data.day }` ],
          [ ``, `Exercise`, `Pain before`, `Pain after`, `Comment` ],
          [ `Feelings`, data.feelings.exercise, data.feelings.painBefore, data.feelings.painAfter, data.feelings.comment ],
        ];
        for (const exercise of data.measures) {
          const d = exercise.measuredData;
          switch(exercise.exerciseIndex) {
              case 2:
                csvdata.push(...[
                  [ ``, `Closing thumb`, `Closing index finger`, `Closing middle finger`, `Closing ring finger`, `Closing pinky`, `Opening thumb`, `Opening index finger`, `Opening middle finger`, `Opening ring finger`, `Opening pinky` ],
                  [ `Big Fist exercise`, d.closingThumb, d.closingIndex, d.closingMiddle, d.closingRing, d.closingPinky, d.openingThumb, d.openingIndex, d.openingMiddle, d.openingRing, d.openingPinky ]
                ]);
                break;
              case 7:
                csvdata.push(...[
                  [ ``, `Wrist side left`, `Wrist side right` ],
                  [ `Wrist E/F exercise`, d.wristSideLeft, d.wristSideRight ]
                ]);
                break;
              case 9:
                csvdata.push(...[
                  [ ``, `Left max`, `Right max` ],
                  [ `Forearm S/P exercise`, d.leftMax, d.rightMax ]
                ]);
                break;
          }
        }
        csv.push(...csvdata);
      }
      return csv;
    }

  const exportData = () => {
      const csv: any = [];
      csv.push(...getHandCSV());
      csv.push(...getFeelingCSV());

      const file = new File([stringify(csv)], `export.csv`)
      const a = document.createElement(`a`);
      a.download = `export.csv`;
      a.href = URL.createObjectURL(file);
      a.click();
  }

  useEffect(() => {
      fetch(
          `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/client/stats?id=${ param!.id }`,
          {
              headers: {
                  Authorization: `Bearer ${ localStorage.getItem(`token`) }`
              }
          }
      ).then(response => {
          response.json().then(body => {
              setStats(body);
          }).catch(exception => {})
      }).catch(exception => {})
  }, []);

  return (
    <main className="client-page">
      <h1 style={{alignItems: `center`, display: `flex`}}>
        <Spacer direction="horizontal" size="middle">
          <>{t("client-page-title")}</>
          <label className="title-text-h1">
            {!isLoading && (data.user?.name ?? data.user?.email)}
          </label>
        </Spacer>
        <Button style={{marginLeft: `auto`}} onClick={ exportData }>Export data</Button>
      </h1>
      {stats ? <HandCharts id={param?.id} stats={stats} /> : null}
      {!isLoading && <ClientTable client={data.user} />}
    </main>
  );
}
