import { Modal } from "antd";

type MutateModalProps = {
  children: JSX.Element | JSX.Element[];
  onCancel: () => void;
  title: string;
};

export function MutateModal({ children, onCancel, title }: MutateModalProps) {
  return (
    <Modal
      open={true}
      title={title}
      onCancel={onCancel}
      footer={[]}
      maskClosable={false}
      centered={true}
    >
      {children}
    </Modal>
  );
}
