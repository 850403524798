import { notification } from "antd";
import { ErrorHandler } from "../../constants";
type NotificationProp = {
  type: "success" | "info" | "warning" | "error";
  message: string;
  error?: any;
  handleClose: () => void;
};

export function Noti({ type, message, error, handleClose }: NotificationProp) {
  const [api, contextHolder] = notification.useNotification({ maxCount: 1 });

  const openNotificationWithIcon = () => {
    api[type]({
      message,
      description: ErrorHandler({ code: error?.response.data.code }),
      duration: 2,
      onClose() {
        handleClose();
      },
    });
  };
  openNotificationWithIcon();

  return <>{contextHolder}</>;
}
