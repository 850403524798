import { Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";
export class ForearmSPEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  minForearmSP!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  avrgForearmSP!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  maxForearmSP!: number;
}
