import {
  BigFistEntity,
  FingerExtensionEntity,
  FingerHyperExtgensionEntity,
  FingerSpreadEntity,
  FingertipEntity,
  ForearmSPEntity,
  GameChartTypes,
  GameEntity,
  MCPFlexionEntity,
  SmallFistEntity,
  TendonGlideEntity,
  WristEFEntity,
  useAppTranslation,
} from "../../constants";
import { CustomChart } from "../chart";
import { Slider } from "../slider";

type SlideShowProps = {
  games: GameEntity[];
};

export function SlideShow({ games }: SlideShowProps) {
  const { t } = useAppTranslation();
  return (
    <Slider>
      <CustomChart
        chartId="game-chart-finger-extension"
        dateTimes={games.map((g: GameEntity) => g.date)}
        minSeriesName={t(GameChartTypes.minFingerExtension)}
        minData={games
          .map((g: GameEntity) => g.fingerExtension)
          .map((fe: FingerExtensionEntity) => fe.minFingerExtension)}
        avrgSeriesName={t(GameChartTypes.avrgFingerExtension)}
        avrgData={games
          .map((g: GameEntity) => g.fingerExtension)
          .map((fe: FingerExtensionEntity) => fe.avrgFingerExtension)}
        maxSeriesName={t(GameChartTypes.maxFingerExtension)}
        maxData={games
          .map((g: GameEntity) => g.fingerExtension)
          .map((fe: FingerExtensionEntity) => fe.maxFingerExtension)}
        chartTitle={t("finger-extension")}
        stepperTitle={t("date")}
        poz="top"
        type="line"
        width="1100"
        height="300"
      />

      <CustomChart
        chartId="game-chart-big-fist"
        dateTimes={games.map((g: GameEntity) => g.date)}
        minSeriesName={t(GameChartTypes.minBigFist)}
        minData={games
          .map((g: GameEntity) => g.bigFist)
          .map((bf: BigFistEntity) => bf.minBigFist)}
        avrgSeriesName={t(GameChartTypes.avrgBigFist)}
        avrgData={games
          .map((g: GameEntity) => g.bigFist)
          .map((bf: BigFistEntity) => bf.avrgBigFist)}
        maxSeriesName={t(GameChartTypes.maxBigFist)}
        maxData={games
          .map((g: GameEntity) => g.bigFist)
          .map((bf: BigFistEntity) => bf.maxBigFist)}
        chartTitle={t("big-fist")}
        stepperTitle={t("date")}
        poz="top"
        type="line"
        width="1100"
        height="300"
      />

      <CustomChart
        chartId="game-chart-finger-hyper"
        dateTimes={games.map((g: GameEntity) => g.date)}
        minSeriesName={t(GameChartTypes.minFingerHyperextgension)}
        minData={games
          .map((g: GameEntity) => g.fingerHyperextgension)
          .map(
            (fhe: FingerHyperExtgensionEntity) => fhe.minFingerHyperextgension
          )}
        avrgSeriesName={t(GameChartTypes.avrgFingerHyperextgension)}
        avrgData={games
          .map((g: GameEntity) => g.fingerHyperextgension)
          .map(
            (fhe: FingerHyperExtgensionEntity) => fhe.avrgFingerHyperextgension
          )}
        maxSeriesName={t(GameChartTypes.maxFingerHyperextgension)}
        maxData={games
          .map((g: GameEntity) => g.fingerHyperextgension)
          .map(
            (fhe: FingerHyperExtgensionEntity) => fhe.maxFingerHyperextgension
          )}
        chartTitle={t("finger-hyperextgension")}
        stepperTitle={t("date")}
        poz="top"
        type="line"
        width="1100"
        height="300"
      />

      <CustomChart
        chartId="game-chart-finger-spread"
        dateTimes={games.map((g: GameEntity) => g.date)}
        minSeriesName={t(GameChartTypes.minFingerSpread)}
        minData={games
          .map((g: GameEntity) => g.fingerSpread)
          .map((fs: FingerSpreadEntity) => fs.minFingerSpread)}
        avrgSeriesName={t(GameChartTypes.avrgFingerSpread)}
        avrgData={games
          .map((g: GameEntity) => g.fingerSpread)
          .map((fs: FingerSpreadEntity) => fs.avrgFingerSpread)}
        maxSeriesName={t(GameChartTypes.maxFingerSpread)}
        maxData={games
          .map((g: GameEntity) => g.fingerSpread)
          .map((fs: FingerSpreadEntity) => fs.maxFingerSpread)}
        chartTitle={t("finger-spread")}
        stepperTitle={t("date")}
        poz="top"
        type="line"
        width="1100"
        height="300"
      />

      <CustomChart
        chartId="game-chart-fingertip"
        dateTimes={games.map((g: GameEntity) => g.date)}
        minSeriesName={t(GameChartTypes.minFingerTip)}
        minData={games
          .map((g: GameEntity) => g.fingerTip)
          .map((ft: FingertipEntity) => ft.minFingertip)}
        avrgSeriesName={t(GameChartTypes.avrgFingerTip)}
        avrgData={games
          .map((g: GameEntity) => g.fingerTip)
          .map((ft: FingertipEntity) => ft.avrgFingertip)}
        maxSeriesName={t(GameChartTypes.maxFingerTip)}
        maxData={games
          .map((g: GameEntity) => g.fingerTip)
          .map((ft: FingertipEntity) => ft.maxFingertip)}
        chartTitle={t("fingertip")}
        stepperTitle={t("date")}
        poz="top"
        type="line"
        width="1100"
        height="300"
      />

      <CustomChart
        chartId="game-chart-forearm-sp"
        dateTimes={games.map((g: GameEntity) => g.date)}
        minSeriesName={t(GameChartTypes.minForearmSp)}
        minData={games
          .map((g: GameEntity) => g.forearmSP)
          .map((fsp: ForearmSPEntity) => fsp.minForearmSP)}
        avrgSeriesName={t(GameChartTypes.avrgForearmSp)}
        avrgData={games
          .map((g: GameEntity) => g.forearmSP)
          .map((fsp: ForearmSPEntity) => fsp.avrgForearmSP)}
        maxSeriesName={t(GameChartTypes.maxForearmSp)}
        maxData={games
          .map((g: GameEntity) => g.forearmSP)
          .map((fsp: ForearmSPEntity) => fsp.maxForearmSP)}
        chartTitle={t("forearm-sp")}
        stepperTitle={t("date")}
        poz="top"
        type="line"
        width="1100"
        height="300"
      />

      <CustomChart
        chartId="game-chart-mcp-flexion"
        dateTimes={games.map((g: GameEntity) => g.date)}
        minSeriesName={t(GameChartTypes.minMCPFlexion)}
        minData={games
          .map((g: GameEntity) => g.mcpFlexion)
          .map((mcpf: MCPFlexionEntity) => mcpf.minMcpFlexion)}
        avrgSeriesName={t(GameChartTypes.avrgMCPFlexion)}
        avrgData={games
          .map((g: GameEntity) => g.mcpFlexion)
          .map((mcpf: MCPFlexionEntity) => mcpf.avrgMcpFlexion)}
        maxSeriesName={t(GameChartTypes.maxMCPFlexion)}
        maxData={games
          .map((g: GameEntity) => g.mcpFlexion)
          .map((mcpf: MCPFlexionEntity) => mcpf.maxMcpFlexion)}
        chartTitle={t("mcp-flexion")}
        stepperTitle={t("date")}
        poz="top"
        type="line"
        width="1100"
        height="300"
      />

      <CustomChart
        chartId="game-chart-small-fist"
        dateTimes={games.map((g: GameEntity) => g.date)}
        minSeriesName={t(GameChartTypes.minSmallFist)}
        minData={games
          .map((g: GameEntity) => g.smallFist)
          .map((sf: SmallFistEntity) => sf.minSmallFist)}
        avrgSeriesName={t(GameChartTypes.avrgSmallFist)}
        avrgData={games
          .map((g: GameEntity) => g.smallFist)
          .map((sf: SmallFistEntity) => sf.avrgSmallFist)}
        maxSeriesName={t(GameChartTypes.maxSmallFist)}
        maxData={games
          .map((g: GameEntity) => g.smallFist)
          .map((sf: SmallFistEntity) => sf.maxSmallFist)}
        chartTitle={t("small-fist")}
        stepperTitle={t("date")}
        poz="top"
        type="line"
        width="1100"
        height="300"
      />

      <CustomChart
        chartId="game-chart-tendon-glide"
        dateTimes={games.map((g: GameEntity) => g.date)}
        minSeriesName={t(GameChartTypes.minTendonGlide)}
        minData={games
          .map((g: GameEntity) => g.tendonGlide)
          .map((tg: TendonGlideEntity) => tg.minTendonGlide)}
        avrgSeriesName={t(GameChartTypes.avrgTendonGlide)}
        avrgData={games
          .map((g: GameEntity) => g.tendonGlide)
          .map((tg: TendonGlideEntity) => tg.avrgTendonGlide)}
        maxSeriesName={t(GameChartTypes.maxTendonGlide)}
        maxData={games
          .map((g: GameEntity) => g.tendonGlide)
          .map((tg: TendonGlideEntity) => tg.maxTendonGlide)}
        chartTitle={t("tendon-glide")}
        stepperTitle={t("date")}
        poz="top"
        type="line"
        width="1100"
        height="300"
      />

      <CustomChart
        chartId="game-chart-wrist-ef"
        dateTimes={games.map((g: GameEntity) => g.date)}
        minSeriesName={t(GameChartTypes.minWristEF)}
        minData={games
          .map((g: GameEntity) => g.wristEF)
          .map((wef: WristEFEntity) => wef.minWristEF)}
        avrgSeriesName={t(GameChartTypes.avrgWristEF)}
        avrgData={games
          .map((g: GameEntity) => g.wristEF)
          .map((wef: WristEFEntity) => wef.avrgWristEF)}
        maxSeriesName={t(GameChartTypes.maxWristEF)}
        maxData={games
          .map((g: GameEntity) => g.wristEF)
          .map((wef: WristEFEntity) => wef.maxWristEF)}
        chartTitle={t("wrist-ef")}
        stepperTitle={t("date")}
        poz="top"
        type="line"
        width="1100"
        height="300"
      />
    </Slider>
  );
}
