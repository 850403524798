export const GameChartTypes = {
  fingerExtension: "finger-extension",
  minFingerExtension: "min-finger-extension",
  avrgFingerExtension: "avrg-finger-extension",
  maxFingerExtension: "max-finger-extension",

  smallFist: "small-fist",
  minSmallFist: "min-small-fist",
  avrgSmallFist: "avrg-small-fist",
  maxSmallFist: "max-small-fist",

  bigFist: "big-fist",
  minBigFist: "min-big-fist",
  avrgBigFist: "avrg-big-fist",
  maxBigFist: "max-big-fist",

  MCPFlexion: "mcp-flexion",
  minMCPFlexion: "min-mcp-flexion",
  avrgMCPFlexion: "avrg-mcp-flexion",
  maxMCPFlexion: "max-mcp-flexion",

  fingerTip: "fingertip",
  minFingerTip: "min-fingertip",
  avrgFingerTip: "avrg-fingertip",
  maxFingerTip: "max-fingertip",

  fingerSpread: "finger-spread",
  minFingerSpread: "min-finger-spread",
  avrgFingerSpread: "avrg-finger-spread",
  maxFingerSpread: "max-finger-spread",

  tendonGlide: "tendon-glide",
  minTendonGlide: "min-tendon-glide",
  avrgTendonGlide: "avrg-tendon-glide",
  maxTendonGlide: "max-tendon-glide",

  wristEF: "wrist-ef",
  minWristEF: "min-wrist-ef",
  avrgWristEF: "avrg-wrist-ef",
  maxWristEF: "max-wrist-ef",

  fingerHyperextgension: "finger-hyperextgension",
  minFingerHyperextgension: "min-finger-hyperextgension",
  avrgFingerHyperextgension: "avrg-finger-hyperextgension",
  maxFingerHyperextgension: "max-finger-hyperextgension",

  forearmSp: "forearm-sp",
  minForearmSp: "min-forearm-sp",
  avrgForearmSp: "avrg-forearm-sp",
  maxForearmSp: "max-forearm-sp",
};
