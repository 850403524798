export const HandTitles = [
  "closing-thumb",
  "closing-index",
  "closing-middle",
  "closing-ring",
  "closing-pinky",
  "opening-thumb",
  "opening-index",
  "opening-middle",
  "opening-ring",
  "opening-pinky",
  "wrist-side-left",
  "wrist-side-right",
  "right-max",
  "left-max",
];
