import { useAppTranslation } from "../hooks";

type ErrorHandlerProps = {
  code: number;
  data?: number;
};

export function ErrorHandler({ code, data }: ErrorHandlerProps) {
  const { t } = useAppTranslation();
  let errorMessage = "";
  switch (code) {
    case 1:
      errorMessage = t("internal-server-error");
      break;
    case 10:
      errorMessage = t("invalid-body-params-error");
      break;
    case 20 || 30:
      errorMessage = t("no-rights-error");
      break;
    case 429:
      errorMessage = t("too-many-login-attempts-error");
      break;
    case 1001:
      errorMessage = t("user-not-found-error");
      break;
    case 1002:
      errorMessage = t("invalid-email-or-password-error");
      break;
    case 1003:
      errorMessage = t("user-already-exist-error");
      break;
    case 1005:
      errorMessage = t("cannot-delete-maintainer-error");
      break;
    case 2001:
      errorMessage = t("version-not-found-error");
      break;
    case 4001:
      errorMessage = t("password-reset-token-invalid-error");
      break;
    case 5001:
      errorMessage = t("session-expired-error");
      break;
    default:
      errorMessage = t("internal-server-error");
      break;
  }
  return errorMessage;
}
