import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../../constants";
import { firstLoginAction } from "../../../actions";
import {
  CustomButton,
  CustomForm,
  ErrorModal,
  FormItem,
  PasswordInput,
} from "../../../components";

export function FirstLoginForm() {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const [state, setState] = useAppState({
    password: "",
    passwordCheck: "",
    modalVisible: false,
  });
  const queryClient = useQueryClient();

  const { mutate, error } = useMutation({
    mutationFn: (data: any) => firstLoginAction(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.userQuery] });
      navigate("/cms");
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const handlePasswordChange = (value: string) => {
    setState((prevState) => ({ ...prevState, password: value }));
  };

  const handlePasswordCheckChange = (value: string) => {
    setState((prevState) => ({ ...prevState, passwordCheck: value }));
  };

  const handleSubmit = () => {
    mutate({
      password: state.password,
      passwordCheck: state.passwordCheck,
    });
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  return (
    <div>
      <CustomForm
        name="first-login"
        onFinish={handleSubmit}
        remember={false}
        layout="vertical"
        scrollToFirstError={true}
      >
        <FormItem
          hasFeedback={false}
          label={t("password")}
          name="Password"
          required={true}
          rules={[
            {
              required: true,
              min: 8,
              max: 255,
              whitespace: true,
              message: t("min-password-requirement"),
            },
          ]}
          tooltip={t("min-password-requirement")}
        >
          <PasswordInput
            id="password"
            bordered={true}
            value={state.password}
            onChange={handlePasswordChange}
            status={undefined}
            placeholder={t("password")}
          />
        </FormItem>
        <FormItem
          hasFeedback={false}
          label={t("password-confirm")}
          name="Password confirm"
          required={true}
          rules={[
            {
              required: state.password.length > 0,
              min: 8,
              max: 255,
              whitespace: true,
              message: t("min-password-requirement"),
            },
          ]}
          tooltip={t("min-password-requirement")}
        >
          <PasswordInput
            id="password-confirm"
            bordered={true}
            value={state.passwordCheck}
            onChange={handlePasswordCheckChange}
            status={undefined}
            placeholder={t("password-confirm")}
          />
        </FormItem>
        <div className="submit-button-container">
          <div className="submit-button">
            <CustomButton
              type="primary"
              key="submit-button"
              htmlType="submit"
              disabled={
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(state.password) ||
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(
                  state.passwordCheck
                ) ||
                state.password !== state.passwordCheck
              }
            >
              <div className="button-text">{t("submit")}</div>
            </CustomButton>
          </div>
        </div>
      </CustomForm>
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </div>
  );
}
