import { Expose } from "class-transformer";
import {
  IsBoolean,
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
} from "class-validator";

export class ClientEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  email!: string;

  @Expose()
  @IsDefined()
  @IsString()
  name!: string;

  @Expose()
  @IsDefined()
  @IsString()
  gender!: string;

  @Expose()
  @IsDefined()
  @IsString()
  operationType!: string;

  @Expose()
  @IsOptional()
  @IsString()
  birthDate?: string | null;

  @Expose()
  @IsDefined()
  @IsString()
  surgeryDate!: string;

  @Expose()
  @IsDefined()
  @IsString()
  castWearingDuration!: string;

  @Expose()
  @IsDefined()
  @IsString()
  whenToRemoveStitchesDate!: string;

  @Expose()
  @IsDefined()
  @IsString()
  weigthTheArmDate!: string;

  @Expose()
  @IsDefined()
  @IsString()
  removeImplants!: string;

  @Expose()
  @IsDefined()
  @IsString()
  handedness!: string;

  @Expose()
  @IsDefined()
  @IsBoolean()
  injured!: boolean;

  @Expose()
  @IsDefined()
  @IsString()
  injuryDate!: string;

  @Expose()
  @IsDefined()
  @IsString()
  injuredSite!: string;

  @Expose()
  @IsDefined()
  @IsBoolean()
  surgery!: boolean;

  @Expose()
  @IsDefined()
  @IsBoolean()
  immobilized!: boolean;

  @Expose()
  @IsDefined()
  @IsBoolean()
  stitched!: boolean;

  @Expose()
  @IsDefined()
  @IsString()
  diagnosis!: string;
}
