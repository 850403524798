import { Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";
export class SmallFistEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  minSmallFist!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  avrgSmallFist!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  maxSmallFist!: number;
}
