import { AdminForm, AdminTable } from "./components";
import { useAppState, useAppTranslation } from "../../constants";
import { CustomButton, MutateModal } from "../../components";
import "./admins.page.scss";

export function AdminsPage() {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    modalVisible: false,
  });

  const handleModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      modalVisible: !state.modalVisible,
    }));
  };

  return (
    <main className="admin-page">
      <h1 className="title-text-h1">{t("admins")}</h1>

      <div className="button-container">
        <CustomButton
          onClick={handleModalVisible}
          type="default"
          key="create-admin"
          htmlType="button"
        >
          <div className="button-text">{t("create-admin-button-title")}</div>
        </CustomButton>
      </div>

      <AdminTable />

      {state.modalVisible && (
        <MutateModal
          title={t("create-admin-button-title")}
          onCancel={handleModalVisible}
        >
          <AdminForm onCancel={handleModalVisible} />
        </MutateModal>
      )}
    </main>
  );
}
