import {CustomChart} from "../../../../components";
import {useAppTranslation} from "../../../../constants";

interface ClosingFistChartProps {
    stats: {
        feelingStats: any,
        handStats: any
    }
}

export const ClosingFistChart = (props: ClosingFistChartProps) => {
    const { t } = useAppTranslation();

    if (!props.stats.handStats.healthyHand) {
        return null
    }

    const max = [
        props.stats.handStats.healthyHand.openingIndex,
        props.stats.handStats.healthyHand.openingMiddle,
        props.stats.handStats.healthyHand.openingRing,
        props.stats.handStats.healthyHand.openingPinky
    ];
    const min = [
        props.stats.handStats.healthyHand.closingIndex,
        props.stats.handStats.healthyHand.closingMiddle,
        props.stats.handStats.healthyHand.closingRing,
        props.stats.handStats.healthyHand.closingPinky
    ];

    const data = props.stats.feelingStats.data.map((d: any) => {
        const measures = d.measures.filter((m: any) => m.exerciseIndex === 2);
        if (measures.length === 0) {
            return null
        }
        const fingers = [ `closingIndex`, `closingMiddle`, `closingRing`, `closingPinky` ];
        const values = [];
        let index = 0;
        for (const finger of fingers) {
            let value = (measures[measures.length - 1].measuredData[finger] - min[index]) / (max[index] - min[index]);
            if (value > 1) {
                value = 1;
            }
            if (value < 0) {
                value = 0;
            }
            index++;
            values.push(value);
        }
        const minValue = values.reduce((min, current) => current < min ? current : min, 1)
        return {
            day: d.day,
            value: 15 - Math.round(minValue * 15 * 100) / 100
        }
    }).filter((d: any) => d !== null)
    if (data.find((d: any) => d.day === 1) === undefined) {
        data.push({ day: 1, value: null })
    }
    if (data.find((d: any) => d.day === 60) === undefined) {
        data.push({ day: 60, value: null })
    }
    data.sort((a: any, b: any) => a.day - b.day);

    return (
        <CustomChart
            type="line"
            chartId="pain-after"
            dateTimes={data.map((data: any) => `Day ${ data.day }`)}
            minSeriesName=""
            minData={data.map((data: any) => data.value)}
            width={"548"}
            height={"400"}
            chartTitle={t("closing-fist-chart-title")}
            stepperTitle={""}
            poz="top"
            max={15}
            min={0}
            markerSize={4}
        />
    )
}