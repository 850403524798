import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CustomPopConfirm,
  DataTable,
  DeleteIcon,
  Noti,
} from "../../../components";
import {
  AdminEntity,
  QueryKeys,
  useAppState,
  useAppTranslation,
} from "../../../constants";
import { deleteAdminAction, getAllAdminAction } from "../../../actions";

export function AdminTable() {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    notiVisible: false,
    successVisible: false,
  });

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.adminQuery],
    queryFn: getAllAdminAction,
  });

  const { mutate, error } = useMutation({
    mutationFn: (id: number) => deleteAdminAction(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.adminQuery] });
      setState((prevState) => ({ ...prevState, successVisible: true }));
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, notiVisible: true }));
    },
  });

  const handleDelete = (id: number) => {
    mutate(id);
  };

  const handleNotiClose = () => {
    setState((prevState) => ({
      ...prevState,
      notiVisible: false,
      successVisible: false,
    }));
  };

  const columns = [
    {
      title: t("name"),
      key: t("name"),
      dataIndex: "name",
    },
    {
      title: t("email"),
      key: t("email"),
      dataIndex: "emailAddress",
    },
    {
      title: t("delete"),
      key: t("delete"),
      render: (text: string, record: AdminEntity) => {
        return (
          <CustomPopConfirm
            title={t("admin-delete-popup-confirm")}
            okText={t("yes")}
            cancelText={t("no")}
            onConfirm={() => handleDelete(record.id)}
            disabled={false}
          >
            <button style={{ backgroundColor: "transparent", border: "none" }}>
              <DeleteIcon />
            </button>
          </CustomPopConfirm>
        );
      },
      width: "100px",
    },
  ];

  return (
    <div>
      {!isLoading && (
        <DataTable
          columns={columns}
          dataSource={data.admins}
          onRowClick={() => null}
          functionDisabler={true}
          scroll={false}
        />
      )}
      {(state.notiVisible || state.successVisible) && (
        <Noti
          type={state.notiVisible ? "error" : "success"}
          message={
            state.notiVisible
              ? t("admin-delete-error")
              : t("admin-deleted-successfully")
          }
          error={state.notiVisible ? error : null}
          handleClose={handleNotiClose}
        />
      )}
    </div>
  );
}
