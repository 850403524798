import { useMutation } from "@tanstack/react-query";
import { useAppState, useAppTranslation } from "../../../constants";
import { forgotPasswordAction } from "../../../actions";
import {
  CustomButton,
  CustomForm,
  CustomInput,
  FormItem,
  Message,
} from "../../../components";

export function ForgotPasswordForm() {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    email: "",
    infoModalVisible: false,
  });
  const handleEmailChange = (value: string) => {
    setState((prevState) => ({ ...prevState, email: value }));
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (data: any) => forgotPasswordAction(data),
    onSuccess() {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
    onError() {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleSubmit = () => {
    mutate({
      email: state.email,
    });
  };

  const handleModalClose = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  return (
    <div>
      <CustomForm
        name="forgot-password"
        onFinish={handleSubmit}
        remember={false}
        layout="vertical"
        scrollToFirstError={false}
      >
        <FormItem
          hasFeedback={false}
          label={t("email")}
          name="email"
          required={true}
        >
          <CustomInput
            bordered={true}
            id="email"
            maxLength={255}
            showCount={false}
            status={undefined}
            type="text"
            value={state.email}
            onChange={handleEmailChange}
            placeholder={t("email")}
          />
        </FormItem>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomButton
            type="primary"
            key="submit-button"
            htmlType="submit"
            loading={isPending}
          >
            <span className="button-text">{t("send")}</span>
          </CustomButton>
        </div>
      </CustomForm>

      {state.infoModalVisible && (
        <Message
          type="success"
          content={t("forgot-password-message")}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
}
