import { ForgotPasswordConfirmForm } from "./components";
import "./forgot-password.page.scss";

export function ForgotPasswordConfirmPage() {
  return (
    <main className="forgot-password-page">
      <ForgotPasswordConfirmForm />
    </main>
  );
}
