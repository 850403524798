import axios from "axios";

export async function getMeAction() {
  const token = localStorage.getItem("token");

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/user/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
