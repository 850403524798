import { Input } from "antd";

type PasswordInputProps = {
  bordered: boolean;
  id: string;
  status: "error" | "warning" | undefined;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
};

export function PasswordInput({
  id,
  bordered,
  value,
  onChange,
  status,
  placeholder,
}: PasswordInputProps) {
  return (
    <Input.Password
      value={value}
      onChange={(e) => onChange(e.target.value)}
      id={id}
      bordered={bordered}
      status={status}
      placeholder={placeholder}
    />
  );
}
